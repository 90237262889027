@use "~@idealsupply/ng-material-idealsupply-theme";
@include ng-material-idealsupply-theme.initTheme();
@include ng-material-idealsupply-theme.useIdealTheme();

@import "./theme";

html {
  overflow-y: auto;
}
body {
  background-color: white;
}
